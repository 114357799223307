import { useGtm } from '@gtm-support/vue-gtm'
import { useUserStore } from '@/store/user'
import cartGetters from '@/utils/getters/cartGetters'
import { useRouter } from '#imports'

export const useGtmTrackEvent = ({
  partnerId, zenniId, campaignType,
  }: {
    partnerId?: string
    zenniId?: string
    campaignType?: string
  } = {
    partnerId: undefined, zenniId: undefined, campaignType: undefined,
  }) => {
  const gtm = useGtm()
  const router = useRouter()
  const userStore = useUserStore()

  const magicLinkClickTrackEvent = () => {
    return gtm?.trackEvent({
      event: 'magic_link_click',
    })
  }

  const loginTrackEvent = (zenniId: string, partnerId: string, params = {}) => {
    return gtm?.trackEvent({
      event: 'login',
      employee_id: zenniId,
      partner_id: partnerId,
      ...params,
    })
  }
  const vtoOpenCameraTrackEvent = (params = {}) => {
    return gtm?.trackEvent({
      event: 'click_open_camera',
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const skipVtoSizeTrackEvent = (params = {}) => {
    return gtm?.trackEvent({
      event: 'click_skip_size',
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const selectedRxTypeTrackEvent = (rxType: string, params = {}) => {
    return gtm?.trackEvent({
      event: 'lens_type_selected',
      lens_type: rxType,
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const selectedPrescriptionTypeTrackEvent = (prescriptionType: string, params = {}) => {
    return gtm?.trackEvent({
      event: 'prescription_type_selected',
      lens_type: prescriptionType,
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const savePrescriptionTrackEvent = (prescriptionType: string, params = {}) => {
    return gtm?.trackEvent({
      event: 'save_prescription',
      save_type: prescriptionType,
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const plpTrackEvent = (
    products: {
      sku?: string
      name?: string
      rgb_color_code?: string
      price: any
    }[],
    params = {},
  ) => {
    const gtmProductList = products?.map(
      (
        item: {
          sku?: string
          name?: string
          rgb_color_code?: string
          price: any
        },
        index: number,
      ) => ({
        index,
        item_id: item?.sku,
        item_name: item?.name,
        item_variant: item?.rgb_color_code,
        price: item?.price,
        item_list_name: 'searchPage',
      }),
    )

    return gtm?.trackEvent({
      event: 'view_item_list',
      ecommerce: {
        item_list_name: 'searchPage',
        items: gtmProductList,
      },
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const plpClickItemTrackEvent = (
    product: {
      sku?: string
      name?: string
      rgb_color_code?: string
      price: any
    },
    index: number,
    params = {},
  ) => {
    const gtmProducts = [
      {
        index,
        item_id: product?.sku,
        item_name: product?.name,
        item_variant: product?.rgb_color_code,
        price: product?.price,
        item_list_name: 'searchPage',
      },
    ]

    return gtm?.trackEvent({
      event: 'select_item',
      ecommerce: {
        item_list_name: 'searchPage',
        items: gtmProducts,
      },
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const pdpTrackEvent = (product: {
    sku?: string
    name?: string
    rgb_color_code?: string
    price: any
  }, params = {}) => {
    const gtmProducts = [
      {
        index: 0,
        item_id: product?.sku,
        item_name: product?.name,
        item_variant: product?.rgb_color_code,
        price: product?.price,
        item_list_name: 'productDetailPage',
      },
    ]

    return gtm?.trackEvent({
      event: 'view_item',
      ecommerce: {
        currency: 'USD',
        value: product?.price,
        items: gtmProducts,
      },
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const pdpClickTryOnTrackEvent = (product: { sku?: string; price: any }, params = {}) => {
    return gtm?.trackEvent({
      event: 'item_try_on',
      product_sku: product?.sku,
      product_price: product?.price,
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const selectedLensTypeTrackEvent = (lensType: string, params = {}) => {
    return gtm?.trackEvent({
      event: 'lens_type_pdp_selected',
      lens_type: lensType,
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const addToCartSunGlassesTrackEvent = (product?: {
    sku?: string
    name?: string
    rgb_color_code?: string
    price?: any
  }, params = {}) => {
    return gtm?.trackEvent({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'USD',
        value: product?.price,
        items: [
          {
            index: 0,
            item_id: product?.sku,
            item_name: product?.name,
            item_variant: product?.rgb_color_code,
            price: product?.price,
            item_list_name: 'productDetailPage',
            quantity: 1,
          },
        ],
      },
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const addToCartFrameGlassesTrackEvent = (product: {
    sku?: string
    name?: string
    rgb_color_code?: string
    price?: any
  }, params = {}) => {
    return gtm?.trackEvent({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'USD',
        value: product?.price,
        items: [
          {
            index: 0,
            item_id: product?.sku,
            item_name: product?.name,
            price: product?.price,
            item_list_name: 'productDetailPage',
            quantity: 1,
          },
        ],
      },
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const removeCartItemTrackEvent = (productSku: string, params = {}) => {
    return gtm?.trackEvent({
      event: 'remove_item_cart',
      product_sku: productSku,
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const clickCheckoutTrackEvent = (product: {
    sku?: string
    price_range?: any
  }, params = {}) => {
    return gtm?.trackEvent({
      event: 'checkout_begin',
      product_sku: product?.sku,
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      items: [
        {
          product_sku: product?.sku,
          product_price: product?.price_range.maximum_price.final_price.value,
          item_list_name: 'productDetailPage',
        },
      ],
      ...params,
    })
  }

  const saveShippingTrackEvent = (params = {}) => {
    return gtm?.trackEvent({
      event: 'checkout_shipping',
      event_action: 'continue_on_shipping',
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const cancelShippingTrackEvent = (params = {}) => {
    return gtm?.trackEvent({
      event: 'checkout_shipping',
      event_action: 'cancel_on_shipping',
      page_location: router.currentRoute.value.name,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const purchaseEvent = (cart: any, placedOrder: string | null, params = {}) => {
    const frame = cartGetters.getFrameItems(cart)[0]
    const lens = cartGetters.getLensItems(cart)[0]
    const totalprice = cart.prices.grand_total.value
    const shippingPrice =
      cart.shipping_addresses[0].selected_shipping_method.amount.value
    const subtotalExcludingTax = cart.prices.subtotal_excluding_tax.value
    return gtm?.trackEvent({
      event: 'purchase',
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ecommerce: {
        transaction_id: placedOrder,
        currency: 'USD',
        value: totalprice,
        tax: totalprice - shippingPrice - subtotalExcludingTax,
        shipping: shippingPrice,
        items: [frame, lens]
          .filter((item) => !!item)
          .map((data) => ({
            item_id: `SKU_${data.sku}`,
            item_name: data.product.name,
            index: 0,
            item_brand: '',
            item_list_name: 'productDetailPage',
            item_variant: '',
            price: data.prices.row_total?.value,
            quantity: 1,
          })),
      },
      ...params,
    })
  }

  const eyebotWelcomeEvent = (type: string = '', params = {}) => {
    return gtm?.trackEvent({
      event: 'eyebot_welcome',
      event_action:
        type === 'rxOnly' ? 'buy prescription only' : 'find my perfect pair',
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
        ...params,
    })
  }
  const eyebotLensSelectionEvent = (sku: string, price: string, params = {}) => {
    return gtm?.trackEvent({
      event: 'eyebot_lens_selection_start_pdp',
      product_sku: sku,
      product_price: price,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      lens_index: '',
      lens_tint_type: '',
      lens_tint_color: '',
      ...params,
    })
  }

  const eyebotLensIndexSelectionEvent = (sku: string, lensIndex: string, params = {}) => {
    return gtm?.trackEvent({
      event: 'eyebot_lens_index_pdp_selected',
      product_sku: sku,
      lens_index: lensIndex,
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  const eyebotLensTintSelectionEvent = (
    sku: string,
    tintType: string,
    tintColor: string,
    params = {}
  ) => {
    return gtm?.trackEvent({
      event: 'eyebot_lens_tint_pdp_selected',
      product_sku: sku,
      lens_tint_type: tintType,
      lens_tint_color: tintColor,
      page_location: router.currentRoute.value.path,
      employee_id: zenniId ||userStore.user?.zenniId,
      partner_id: partnerId || userStore.store?.partnerId,
      campaignType,
      ...params,
    })
  }

  return {
    eyebotWelcomeEvent,
    eyebotLensSelectionEvent,
    eyebotLensIndexSelectionEvent,
    eyebotLensTintSelectionEvent,
    loginTrackEvent,
    vtoOpenCameraTrackEvent,
    skipVtoSizeTrackEvent,
    selectedRxTypeTrackEvent,
    selectedPrescriptionTypeTrackEvent,
    savePrescriptionTrackEvent,
    plpTrackEvent,
    plpClickItemTrackEvent,
    pdpTrackEvent,
    pdpClickTryOnTrackEvent,
    selectedLensTypeTrackEvent,
    addToCartSunGlassesTrackEvent,
    addToCartFrameGlassesTrackEvent,
    removeCartItemTrackEvent,
    clickCheckoutTrackEvent,
    saveShippingTrackEvent,
    cancelShippingTrackEvent,
    purchaseEvent,
    magicLinkClickTrackEvent,
  }
}
