import { storeToRefs, useGtmTrackEvent } from '#imports'
import { useGuestStore } from '~/store/guest'

export const useEmployeeGtmTrackEvent = () => {
  const guestStore = useGuestStore()
  const { guest, currentGift } = storeToRefs(guestStore)

  return useGtmTrackEvent({
    partnerId: currentGift.value?.partnerId,
    zenniId: guest.value?.userId,
    campaignType: currentGift.value?.catalog?.[0],
  })
}